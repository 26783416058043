<template>
  <div :class="$style.root">
    <div v-if="selectedColorLabel" :class="$style.selected_label">
      <p>{{ selectedColorLabel }}</p>
      <p>{{ selectedSkuColor }}</p>
    </div>
    <div :class="$style.colors">
      <AtomsConsumerButton
        v-for="select in selectColors"
        :key="select.color"
        :class="[$style.image, { [$style.selected]: selectedSku && selectedSku.color === select.color }]"
        @click.stop="changeSelectedSku(select.color, select.size)"
      >
        <div
          v-if="colorType === 'color_code'"
          :class="$style.chip"
          :style="{ background: select.color_code || '#000000' }"
        />
        <AtomsConsumerImage v-else-if="colorType === `chip_image`" :src="select.chip_image" :width="60" :height="80" />
        <AtomsConsumerImage v-else :src="select.main_image" :width="60" :height="80" />
      </AtomsConsumerButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SkuFragmentFragment } from "~/types/type.generated"
import { EventHandler } from "~/types/events"

type Sku = Pick<SkuFragmentFragment, "color" | "size" | "color_code" | "main_image" | "chip_image_url">
type Props = {
  selectedSku?: Sku | null
  cartIndex?: number
  skus: Sku[]
  clickEvent: (sku: Sku, isColor: boolean, cartIndex: number) => void
  selectedColorLabel?: string
  colorType?: string
}

const props = withDefaults(defineProps<Props>(), {
  selectedSku: null,
  cartIndex: 0,
  skus: () => [],
  selectedColorLabel: "",
  colorType: "color_code",
})

const selectedSkuColor = computed(() => {
  if (props.selectedSku?.color) return props.selectedSku.color
  return ""
})

const selectColors = computed(() => {
  if (props.skus) {
    const selectColors: { color: string; size: string; color_code: string; main_image: string; chip_image: string }[] =
      []
    props.skus.forEach((sku) => {
      const existSameColorSelect = selectColors.some(
        (select) => typeof select.color === "string" && select.color === sku.color,
      )
      if (!existSameColorSelect)
        selectColors.push({
          color: sku.color ?? "",
          size: sku.size,
          color_code: sku.color_code ?? "",
          main_image: sku.main_image ?? "",
          chip_image: sku.chip_image_url ?? "",
        })
    })
    return selectColors
  }
  return []
})

/*
 * changeSelectedSku: 選択されたSKUを変更する
 * @params color: string
 */
const changeSelectedSku: EventHandler = (color: string) => {
  const findSku = props.skus.find((sku) => {
    if (props.selectedSku) return sku.size === props.selectedSku.size && sku.color === color
    return false
  })

  if (findSku) return props.clickEvent(findSku, true, props.cartIndex)

  const findSelectColorSku = props.skus.find((sku) => sku.color === color)
  findSelectColorSku
    ? props.clickEvent(findSelectColorSku, true, props.cartIndex)
    : props.clickEvent(props.skus[0], true, props.cartIndex)
}
</script>

<style scoped module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;

  .selected_label {
    display: flex;
    gap: 0.75rem;

    p {
      font-size: $font-size-12;
      font-weight: 400;

      @include md {
        font-size: $font-size-14;
      }
    }
  }

  .colors {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    .image {
      box-sizing: border-box;
      width: 60px;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;

      &.selected {
        border: 1px solid $white;
        outline: 2px solid $secondary;
      }

      .chip {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include background-skeleton-loading;
      }
    }
  }
}
</style>
